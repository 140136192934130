import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({spacing, breakpoints}) => ({
  jobContainer: {
    margin: spacing(0, 20),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(8, 0),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: 0,
    },
  },
  director: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    width: 140,
    '& h1': {
      padding: spacing(4, 0, 1, 0),
    },
    '& svg': {
      paddingLeft: spacing(7),
    },
  },
  description: {
    width: '80%',
    paddingLeft: spacing(10),
    [breakpoints.down('sm')]: {
      width: '100%',
      padding: spacing(5, 0),
    },
  },
  jobTitle: {
    margin: 0,
  },
}))

export default useStyles
