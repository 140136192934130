import * as R from 'ramda'
import React from 'react'

import {managersPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import Media from 'components/UI/Media'
import RawText from 'components/UI/RawText'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './styles'

const JobSummary = ({managers}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  return (
    <div className={classes.container}>
      {mapIndexed((manager, index) => {
        const isGrey = index % 2 === 0

        return (
          <Section
            hasPaddingTop={false}
            hasPaddingBottom={false}
            hasGreyBackground={isGrey}
          >
            <div className={classes.jobContainer}>
              <div className={classes.director}>
                <Media data={manager.photo} isRoundImage />
                <Title type="innerTitle">{manager.name}</Title>
                <Description className={classes.jobTitle} type="description">
                  {manager.jobTitle}
                </Description>
                <LinkedInIcon style={{color: '#0077b5'}} />
              </div>
              <div className={classes.description}>
                <RawText text={manager.biography} />
              </div>
            </div>
          </Section>
        )
      }, managers)}
    </div>
  )
}

JobSummary.propTypes = managersPropTypes

JobSummary.defaultProps = {
  biography: {
    raw: '',
  },
  jobTitle: '',
  linkedInProfile: '',
  name: '',
  photo: null,
}

export default JobSummary
